import React from 'react';
import {usePopularDomain} from '../lib/hooks/use-popular-domains';
import {ChartComponentProps} from '../lib/typings';
import {DataBlockWrapper} from './DataBlockWrapper';
import styled from 'styled-components';

const Table = styled.table`
  font-size: 12px;
  width: 100%;

  td {
    border: 1px solid #f1f2f6;
    padding: 3px;
  }

  th {
    padding: 3px;
    background-color: #a4b0be;
    color: white;
  }
`;

const getDiffString = (diff: number) =>
  diff === 0 ? '-' : diff > 0 ? `+${diff}` : diff;
const getDiffStyle = (diff: number) => ({
  color: diff === 0 ? 'black' : diff > 0 ? 'green' : 'red',
  fontWeight: 'bold',
});

export const PopularDomainChart: React.FC<ChartComponentProps> = React.memo(
  ({gridPosition}) => {
    const {isLoading, isError, data} = usePopularDomain();

    const renderTable = React.useMemo(
      () =>
        !data ? (
          <p>No data to show</p>
        ) : (
          <Table>
            <tr>
              <th>#</th>
              <th>Domain</th>
              <th>Category</th>
              <th>Change</th>
            </tr>
            {data.rankingEntries.map(({rank, domain, category, rankChange}) => {
              return (
                <tr key={rank}>
                  <td>{rank}</td>
                  <td>{domain}</td>
                  <td>{category}</td>
                  <td style={getDiffStyle(rankChange)}>
                    {getDiffString(rankChange)}
                  </td>
                </tr>
              );
            })}
          </Table>
        ),
      [data]
    );

    return (
      <DataBlockWrapper
        title="Popular Domain over the last 30 days"
        gridPosition={gridPosition}
        isLoading={isLoading}
        isError={isError}
      >
          {renderTable}
      </DataBlockWrapper>
    );
  }
);
