import {API_URL} from './constants';

export const fetchWorker = async <T>(url: string): Promise<T> => {
  const res = await fetch(`${API_URL}/${url}`, {method: 'GET'});

  if (!res.ok) {
    throw new Error('An error occurred');
  }

  const data = await res.json();

  return data;
};
