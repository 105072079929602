import {useState, useEffect} from 'react';
import {fetchWorker} from '../fetch-worker';

export const useFetchWorker = <T>(url: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [data, setData] = useState<T | null>(null);

  const attemptFetchWorker = async () => {
    try {
      setIsLoading(true);
      const data = await fetchWorker<T>(url);
      setData(data);
    } catch (e) {
      console.error(e);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    attemptFetchWorker();
  }, []);

  return {isLoading, isError, data};
};
