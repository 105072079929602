import React from 'react';
import styled from 'styled-components';
import {THEME} from '../lib/constants';

interface DataBlockWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  isError: boolean;
  title: string;
  gridPosition: string;
}

const Container = styled.div<{gridPosition: string}>`
  background-color: white;
  border-radius: 3px;
  padding: 8px;
  border-top: 3px solid ${THEME.mainColor};
  grid-area: ${({gridPosition}) => gridPosition};
  overflow: scroll;
`;

const LoadingOverlay = styled.div<{gridPosition: string}>`
  background-color: white;
  opacity: 0.7;
  border-radius: 3px;
  grid-area: ${({gridPosition}) => gridPosition};
  z-index: 90;
`;

const OverlayTextContainer = styled.div<{
  gridPosition: string;
  isError: boolean;
}>`
  background: none;
  border-radius: 3px;
  grid-area: ${({gridPosition}) => gridPosition};
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({isError}) => (isError ? 'red' : 'black')};
  z-index: 100;
`;

const Title = styled.h1`
  color: ${THEME.mainColor};
  font-size: 24px;
  padding: 0px;
  margin: 0px;
  margin-bottom: 10px;
`;

export const DataBlockWrapper: React.FC<DataBlockWrapperProps> = ({
  title,
  children,
  gridPosition,
  isLoading,
  isError,
}) => {
  return (
    <>
      {(isLoading || isError) && (
        <>
          <LoadingOverlay gridPosition={gridPosition} />
          <OverlayTextContainer gridPosition={gridPosition} isError={isError}>
            {isError ? 'Error occurred...' : 'Loading...'}
          </OverlayTextContainer>
        </>
      )}
      <Container gridPosition={gridPosition}>
        <Title>{title}</Title>
        {children}
      </Container>
    </>
  );
};
