export const API_URL = 'https://workers.nedjulius.workers.dev';

export const TRAFFIC_CHANGE_URL = `traffic-change`;
export const POPULAR_DOMAINS_URL = `popular-domains`;
export const ATTACK_LAYER3_URL = `attack-layer3`;

export const THEME = {
  mainColor: '#e84118',
  secondaryColor: '#5352ed',
};
