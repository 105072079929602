import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {THEME} from '../lib/constants';
import {useAttackLayerThree} from '../lib/hooks/use-attack-layer-three';
import {ChartComponentProps} from '../lib/typings';
import {formatPercentageTick} from '../lib/utils';
import {DataBlockWrapper} from './DataBlockWrapper';

export const AttackLayerThreeChart: React.FC<ChartComponentProps> = React.memo(
  ({gridPosition}) => {
    const {isLoading, isError, data} = useAttackLayerThree();

    const chartData = React.useMemo(() => {
      if (!data) return [];

      return data.data.total.values.map((value, index) => {
        return {
          date: new Date(data.data.total.timestamps[index]),
          percentage: value * 100,
        };
      });
    }, [data]);

    return (
      <DataBlockWrapper
        title="Layer 3 DDoS Attack over the last 30 days"
        gridPosition={gridPosition}
        isLoading={isLoading}
        isError={isError}
      >
        <ResponsiveContainer width="100%" height="90%">
          <LineChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={false} label="Date" />
            <YAxis domain={[0, 100]} tickFormatter={formatPercentageTick} />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="percentage"
              stroke={THEME.mainColor}
              activeDot={{r: 6}}
            />
          </LineChart>
        </ResponsiveContainer>
      </DataBlockWrapper>
    );
  }
);
