import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {THEME} from '../lib/constants';
import {useTrafficChange} from '../lib/hooks/use-traffic-change';
import {ChartComponentProps} from '../lib/typings';
import {formatPercentageTick} from '../lib/utils';
import {DataBlockWrapper} from './DataBlockWrapper';

export const TrafficChangeChart: React.FC<ChartComponentProps> = React.memo(
  ({gridPosition}) => {
    const {isLoading, isError, data} = useTrafficChange();
    console.log(data);

    const chartData = React.useMemo(() => {
      if (!data) return [];

      const dataPoints = data.data;

      return [...new Array(dataPoints.http.values.length)].map((_, index) => ({
        date: new Date(dataPoints.http.timestamps[index]),
        total: dataPoints.total.values[index] * 100,
        http: dataPoints.http.values[index] * 100,
      }));
    }, [data]);

    return (
      <DataBlockWrapper
        title="Traffic Change (Total / HTTP) over the last 30 days"
        isLoading={isLoading}
        isError={isError}
        gridPosition={gridPosition}
      >
        <ResponsiveContainer width="100%" height="90%">
          <LineChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={false} label="Date" />
            <YAxis
              tickCount={4}
              domain={[30, 100]}
              tickFormatter={formatPercentageTick}
            />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="total"
              stroke={THEME.mainColor}
              activeDot={{r: 6}}
            />
            <Line
              type="monotone"
              dataKey="http"
              stroke={THEME.secondaryColor}
              activeDot={{r: 6}}
            />
          </LineChart>
        </ResponsiveContainer>
      </DataBlockWrapper>
    );
  }
);
