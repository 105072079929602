import React from 'react';
import styled from 'styled-components';
import {AttackLayerThreeChart} from './AttackLayerThreeChart';
import {PopularDomainChart} from './PopularDomainChart';
import {TrafficChangeChart} from './TrafficChangeChart';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

function App() {
  return (
    <AppContainer>
      <TrafficChangeChart gridPosition="1 / 1 / 3 / 5" />
      <PopularDomainChart gridPosition="3 / 1 / 5 / 2" />
      <AttackLayerThreeChart gridPosition="3 / 2 / 5 / 5" />
    </AppContainer>
  );
}

export default App;
